import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";

class ApiService {
  private client: AxiosInstance;

  constructor(baseURL: string) {
    this.client = axios.create({
      baseURL,
    });
  }

  // 공통 헤더 설정 메서드
  private getHeaders(
    isFormData: boolean,
    token?: string
  ): Record<string, string> {
    const headers: Record<string, string> = {};

    headers["Content-Type"] = isFormData
      ? "multipart/form-data"
      : "application/json";

      console.log(token);
    if (token) {
      headers.Authorization = `Token ${token}`;
    }

    return headers;
  }

  // POST 요청 메서드
  public async post<T>(
    url: string,
    data: any, // FormData 또는 일반 객체
    token?: string
  ): Promise<AxiosResponse<T>> {
    try {
      const isFormData = data instanceof FormData;
      const headers = this.getHeaders(isFormData, token);

      const response = await this.client.post<T>(url, data, { headers });
      return response;
    } catch (error) {
      this.handleError(error as AxiosError);
      throw error;
    }
  }

  // GET 요청 메서드
  public async get<T>(
    url: string,
    params?: any,
    token?: string
  ): Promise<AxiosResponse<T>> {
    try {
      const headers = this.getHeaders(false, token);

      const response = await this.client.get<T>(url, { headers, params });
      return response;
    } catch (error) {
      this.handleError(error as AxiosError);
      throw error;
    }
  }

  // 에러 핸들링 메서드
  private handleError(error: AxiosError) {
    console.error("API 호출 중 오류 발생:", error.message);

    if (error.response) {
      console.error("응답 데이터:", error.response.data);
      console.error("응답 상태 코드:", error.response.status);
    } else if (error.request) {
      console.error("응답을 받지 못함:", error.request);
    } else {
      console.error("요청 설정 중 오류 발생:", error.message);
    }
  }
}

export default ApiService;
